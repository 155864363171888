import { default as AboutuDxQyq8rxRMeta } from "/opt/build/repo/pages/About.vue?macro=true";
import { default as DeniedL7TfQ7UAMOMeta } from "/opt/build/repo/pages/admin/Denied.vue?macro=true";
import { default as index7qcPXZQ3fgMeta } from "/opt/build/repo/pages/admin/index.vue?macro=true";
import { default as ordersgPLe4ta9oVMeta } from "/opt/build/repo/pages/admin/orders.vue?macro=true";
import { default as productsFy8iFNl694Meta } from "/opt/build/repo/pages/admin/products.vue?macro=true";
import { default as CancelH8dJUAlT22Meta } from "/opt/build/repo/pages/Cancel.vue?macro=true";
import { default as cartl5ckCViQWgMeta } from "/opt/build/repo/pages/cart.vue?macro=true";
import { default as ChattokProof47ahxnZjOPMeta } from "/opt/build/repo/pages/ChattokProof.vue?macro=true";
import { default as Checkout2sMnNmUQ0fMeta } from "/opt/build/repo/pages/Checkout.vue?macro=true";
import { default as Contact_45usJk0sGNMtIYMeta } from "/opt/build/repo/pages/Contact-us.vue?macro=true";
import { default as GuideMeO2kXBtL37JMeta } from "/opt/build/repo/pages/GuideMe.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as index9WruYoq02MMeta } from "/opt/build/repo/pages/login/index.vue?macro=true";
import { default as resetPassword7xbb9LGtRrMeta } from "/opt/build/repo/pages/login/resetPassword.vue?macro=true";
import { default as verifyOTPR8tXs5gf1uMeta } from "/opt/build/repo/pages/login/verifyOTP.vue?macro=true";
import { default as signupQ6T3vUFXXDMeta } from "/opt/build/repo/pages/signup.vue?macro=true";
import { default as StripecRNH0Ldr3L9Meta } from "/opt/build/repo/pages/Stripec.vue?macro=true";
import { default as Subsq7bQdMT8CsMeta } from "/opt/build/repo/pages/Subs.vue?macro=true";
import { default as success5PxaWcwlslMeta } from "/opt/build/repo/pages/success.vue?macro=true";
import { default as TermsOfServicetO4iiR4NxyMeta } from "/opt/build/repo/pages/TermsOfService.vue?macro=true";
import { default as accountOyJ5QNbknrMeta } from "/opt/build/repo/pages/user/account.vue?macro=true";
import { default as indexuu6yzSj8DAMeta } from "/opt/build/repo/pages/user/index.vue?macro=true";
import { default as _91invoice_93BPEvdhyIVWMeta } from "/opt/build/repo/pages/user/orders/[invoice].vue?macro=true";
import { default as _91orderId_93KUHzvmwnCaMeta } from "/opt/build/repo/pages/user/orders/[orderId].vue?macro=true";
import { default as indexF17oWZXNlPMeta } from "/opt/build/repo/pages/user/orders/index.vue?macro=true";
export default [
  {
    name: "About",
    path: "/About",
    component: () => import("/opt/build/repo/pages/About.vue").then(m => m.default || m)
  },
  {
    name: "admin-Denied",
    path: "/admin/Denied",
    component: () => import("/opt/build/repo/pages/admin/Denied.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/opt/build/repo/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-orders",
    path: "/admin/orders",
    component: () => import("/opt/build/repo/pages/admin/orders.vue").then(m => m.default || m)
  },
  {
    name: "admin-products",
    path: "/admin/products",
    component: () => import("/opt/build/repo/pages/admin/products.vue").then(m => m.default || m)
  },
  {
    name: "Cancel",
    path: "/Cancel",
    component: () => import("/opt/build/repo/pages/Cancel.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/opt/build/repo/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "ChattokProof",
    path: "/ChattokProof",
    component: () => import("/opt/build/repo/pages/ChattokProof.vue").then(m => m.default || m)
  },
  {
    name: "Checkout",
    path: "/Checkout",
    component: () => import("/opt/build/repo/pages/Checkout.vue").then(m => m.default || m)
  },
  {
    name: "Contact-us",
    path: "/Contact-us",
    component: () => import("/opt/build/repo/pages/Contact-us.vue").then(m => m.default || m)
  },
  {
    name: "GuideMe",
    path: "/GuideMe",
    component: () => import("/opt/build/repo/pages/GuideMe.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/build/repo/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login-resetPassword",
    path: "/login/resetPassword",
    component: () => import("/opt/build/repo/pages/login/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: "login-verifyOTP",
    path: "/login/verifyOTP",
    component: () => import("/opt/build/repo/pages/login/verifyOTP.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/opt/build/repo/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "Stripec",
    path: "/Stripec",
    component: () => import("/opt/build/repo/pages/Stripec.vue").then(m => m.default || m)
  },
  {
    name: "Subs",
    path: "/Subs",
    component: () => import("/opt/build/repo/pages/Subs.vue").then(m => m.default || m)
  },
  {
    name: "success",
    path: "/success",
    component: () => import("/opt/build/repo/pages/success.vue").then(m => m.default || m)
  },
  {
    name: "TermsOfService",
    path: "/TermsOfService",
    component: () => import("/opt/build/repo/pages/TermsOfService.vue").then(m => m.default || m)
  },
  {
    name: "user-account",
    path: "/user/account",
    component: () => import("/opt/build/repo/pages/user/account.vue").then(m => m.default || m)
  },
  {
    name: "user",
    path: "/user",
    component: () => import("/opt/build/repo/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-orders-invoice",
    path: "/user/orders/:invoice()",
    component: () => import("/opt/build/repo/pages/user/orders/[invoice].vue").then(m => m.default || m)
  },
  {
    name: "user-orders-orderId",
    path: "/user/orders/:orderId()",
    component: () => import("/opt/build/repo/pages/user/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "user-orders",
    path: "/user/orders",
    component: () => import("/opt/build/repo/pages/user/orders/index.vue").then(m => m.default || m)
  }
]