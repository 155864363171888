import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_2ruDq4uEtU from "/opt/build/repo/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import _0_siteConfig_MwZUzHrRNP from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import auth_redirect_bWtzICY6fh from "/opt/build/repo/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import plugin_C2QvvtgUo3 from "/opt/build/repo/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import plugin_gab9g3XuEx from "/opt/build/repo/node_modules/nuxt-aos/dist/runtime/plugin.js";
import plugin_hwlJ5PmHMh from "/opt/build/repo/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import plugin_client_UYiXMU8ZyN from "/opt/build/repo/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_8BKffvaEi5 from "/opt/build/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/opt/build/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import Vue3Lottie_client_bMZiOS6AB0 from "/opt/build/repo/plugins/Vue3Lottie.client.ts";
import pinia_48xmdi2HHl from "/opt/build/repo/plugins/pinia.ts";
import vuetify_7h9QAQEssH from "/opt/build/repo/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_2ruDq4uEtU,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  auth_redirect_bWtzICY6fh,
  plugin_C2QvvtgUo3,
  plugin_gab9g3XuEx,
  plugin_hwlJ5PmHMh,
  plugin_client_UYiXMU8ZyN,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  Vue3Lottie_client_bMZiOS6AB0,
  pinia_48xmdi2HHl,
  vuetify_7h9QAQEssH
]